import React from 'react'
import injectSheet from 'react-jss';
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import 'dayjs/locale/de';

import Layout from '../containers/layout'
import SubPage from '../containers/subpage'
import SmallBox from "../components/SmallBox";


const styles = theme => {
    return {
        root: {
        },
        infoContainer: {

            display: 'flex',
            '& > *': {
                width: '50%',
            },
            [theme.breakpoints.down('md')]: {
                '& > *': {
                    width: '100%',
                    marginLeft:0,
                    marginRight:0,
                },
                flexWrap: 'wrap',
            }
        },
        content:{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            padding: '12px 24px',
        },
        text:{

        },
        labeledList:{
            '& > *': {
                display: 'flex',
                marginTop: '.8em',
                '& > *:first-child':{
                    width: '8em',
                    flex: '0 0 auto',
                }
            }
        },
    };
};

class SchulenPage extends React.Component{
    render() {
        const {classes, data} = this.props;

        let gatsbyImg=null;

        if(data.background && data.background.childImageSharp){
            gatsbyImg = (
                <Img
                    fluid={data.background.childImageSharp.fluid}
                />
            );
        }

        return (
            <Layout>
                <SubPage
                    image={gatsbyImg}
                    header={(<h2>Schulen</h2>)}
                >
                <div className={classes.root + ' container'}>
                    <h4>Vom 6.-10 November 2018 finden in Zürich die Deutschsprachigen Poetry Slam Meisterschaften statt. Der sogenannte «Slam2018» ist der wichtigste und grösste Anlass der deutschsprachigen Poetry Slam Szene. Die rund 200 besten Poetinnen und Poeten wetteifern mit ihren Texten in Vorrunden, Halbfinals und Finals um die Gunst des Publikums. Angetreten wird in den Kategorien Einzel und Team.</h4>
                    <p>
                        Seit vielen Jahren besuchen Slampoetinnen und -poeten Schulklassen in der ganzen Schweiz. Sie arbeiten gemeinsam mit den Schülerinnen und Schülern an Texten und üben zusammen die Vortragskunst. Am „Slam2018“ möchten wir Schulklassen die Gelegenheit geben, an diesem bedeutendsten Poetry-Slam-Wettbewerb Europas dabei zu sein und den Slam live zu erleben. Zu hören und sehen gibt es packende Geschichten, schnellen Rap oder berührende Lyrik von Poetinnen und Poeten von Kiel bis Wien und darüber hinaus.
                    </p>
                    <div className={classes.infoContainer}>
                        <SmallBox header="Programm">
                            <div className={classes.content}>
                                <p>Für folgende Einzel-Vorrunden, Einzel- und Teamhalbfinals bieten wir für Schulklassen reduzierte Eintrittspreise an. Die Auswahl richtet sich nach organisatorischen Überlegungen.</p>
                                <h4>Mittwoch, 7. November</h4>
                                <p>
                                    <Link to="/programm/vorrunde-3/">Einzel-Vorrunde 3, 18.30 Uhr – 20.30 Uhr (Plaza)</Link><br/>
                                    <Link to="/programm/vorrunde-6/">Einzel-Vorrunde 6, 21.00 Uhr  – 20.30 Uhr (Plaza)</Link>
                                </p>
                                <h4>Donnerstag, 8. November</h4>
                                <p>
                                    <Link to="/programm/vorrunde-8/">Einzel-Vorrunde 8, 18.30 Uhr – 20.30 Uhr (X-tra)</Link><br/>
                                    <Link to="/programm/vorrunde-10/">Einzel-Vorrunde 10, 18.30 Uhr- 20.30 Uhr (Volkshaus)</Link><br/>
                                    <Link to="/programm/team-halbfinale-2/">Team-Halbfinal 2, 21.00 Uhr – 23.00 Uhr (Volkshaus)</Link>
                                </p>
                                <h4>Freitag, 9. November</h4>
                                <p>
                                    <Link to="/programm/einzel-halbfinale-1/">Einzel-Halbfinal 1, 18.30 Uhr – 20.30 Uhr (Volkshaus)</Link><br/>
                                    <Link to="/programm/einzel-halbfinale-2/">Einzel-Halbfinal 2, 18.30 Uhr – 20.30 Uhr (Plaza)</Link>
                                </p>
                                <h4>Samstag, 10. November</h4>
                                <p>
                                    <Link to="/programm/einzel-halbfinale-3/">Einzel-Halbfinal 3, 17.30 Uhr- 19-30 Uhr (Plaza)</Link>
                                </p>
                            </div>
                        </SmallBox>
                        <SmallBox header="Konditionen">
                            <div className={classes.content}>
                                <div className={classes.labeledList}>
                                    <div>
                                        <span>Zielpublikum:</span>
                                        <span>8. – 10. Schuljahr, Mittel- und Berufsschulen</span>
                                    </div>
                                    <div>
                                        <span>Kosten / Stehplatz:</span>
                                        <span>Einzelvorrunden: CHF 10 statt CHF 18.80<br/>
                                    Halbfinal (Team oder Einzel): CHF 20 statt CHF 25.90 bzw. CHF 29</span>
                                    </div>
                                    <div>
                                        <span>Bezahlung:</span>
                                        <span>Per Rechnung im Vorraus oder direkt an der Abendkasse</span>
                                    </div>
                                    <div>
                                        <span>Reservation:</span>
                                        <span>gerne per E-Mail an <a href="mailto:schulen@slam2018.ch">schulen@slam2018.ch</a></span>
                                    </div>
                                </div>
                                <p>
                                    Für Fragen stehen wir Ihnen gerne unter <a href="mailto:schulen@slam2018.ch">schulen@slam2018.ch</a> zur Verfügung. Wir freuen uns sehr auf Ihren Besuch mit Ihren Schülerinnen und Schüler.
                                </p>
                            </div>
                        </SmallBox>
                    </div>
                </div>
                </SubPage>
            </Layout>
        );
    };
}


export default injectSheet(styles)(SchulenPage);

export const query = graphql`
    query SchulenPageQuery {
        background: file(relativePath: {eq: "S@S_Ausscheidung_SeelandgymBiel-16.png"}){
            childImageSharp{
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`;